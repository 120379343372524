import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {transformEvent} from '../../../../commons/services/seo'
import {getMultilingualLanguage, isMainLanguage} from '../../../../commons/utils/wix-code-api'
import {State} from '../types/state'

interface RenderSEOTagsParams {
  wixCodeApi: IWixAPI
  state: State
  pageUrl: string
}

export const renderSEOTags = async ({wixCodeApi, state, pageUrl}: RenderSEOTagsParams) => {
  try {
    const language = isMainLanguage(wixCodeApi) ? undefined : getMultilingualLanguage(wixCodeApi)
    const events = state.events.events.map(event => transformEvent(event, pageUrl, language, state.dates.events))

    if (events) {
      wixCodeApi.seo.renderSEOTags({
        itemType: 'EVENTS_LIST_COMPONENT',
        itemData: {events},
      })
    }
  } catch (e) {
    console.log(e)
  }
}
