import {AnyAction} from 'redux'
import {MEMBER_LOGGED_IN, PROMPT_LOGIN} from '../actions/members'

export const memberLoggedIn = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case PROMPT_LOGIN.SUCCESS:
      return action.payload
    case MEMBER_LOGGED_IN:
      return true
    default:
      return state
  }
}
