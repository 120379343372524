import {createReducer} from '@reduxjs/toolkit'
import {setVeloCategoryFilter} from '../actions/velo-overrides'

export interface DynamicVeloOverridesState {
  categoryId: string
}

const initialState: DynamicVeloOverridesState = {
  categoryId: null,
}

export const dynamicVeloOverrides = createReducer(initialState, builder => {
  builder.addCase(setVeloCategoryFilter, (state, {payload}) => {
    state.categoryId = payload
  })
})
