import {addQueryParams, stringifyParams} from '@wix/panda-js-utils'
import {
  EVENT_FILTER_TYPE,
  EVENT_SORT_ORDER,
  EventsHttpClient,
  LIST_LAYOUT,
  RECURRING_FILTER,
  WIDGET_TYPE,
  WidgetData,
} from '@wix/wix-events-commons-statics'

export interface GetWidgetDataParams {
  compId: string
  language: string
  locale: string
  tz: string
  viewMode: string
  members: boolean
  paidPlans: boolean
  responsive: boolean
  showcase: boolean
  widgetType?: WIDGET_TYPE
  listLayout?: LIST_LAYOUT
  recurringFilter?: RECURRING_FILTER
  filterType?: EVENT_FILTER_TYPE
  sortOrder?: EVENT_SORT_ORDER
  categoryId?: string
  noCategoryIdFallback?: boolean
  limit?: number
  membersV2?: boolean
}

export const getWidgetData =
  (api: EventsHttpClient) =>
  async ({
    responsive = false,
    locale,
    language,
    compId,
    viewMode,
    members,
    paidPlans,
    widgetType,
    listLayout,
    recurringFilter,
    showcase = false,
    tz,
    filterType,
    sortOrder,
    categoryId,
    noCategoryIdFallback,
    limit,
    membersV2,
  }: GetWidgetDataParams): Promise<WidgetData> =>
    api.get<WidgetData>(
      addQueryParams(
        `/html/v2/widget-data`,
        stringifyParams({
          compId,
          locale: language,
          regional: locale,
          viewMode,
          members,
          paidPlans,
          responsive,
          widgetType,
          listLayout,
          recurringFilter,
          showcase,
          tz,
          filterType,
          sortOrder,
          categoryId,
          noCategoryIdFallback,
          limit,
          membersV2,
        }),
      ),
    )
