import {AnyAction} from 'redux'
import {WidgetComponentConfig} from '../../types'
import {updateStyleParams} from '../actions/component'

const defaultState: WidgetComponentConfig = {} as WidgetComponentConfig

export const component = (state = defaultState, action: AnyAction): WidgetComponentConfig => {
  switch (action.type) {
    case updateStyleParams.toString():
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload.numbers,
          ...action.payload.booleans,
        },
      }
    default:
      return state
  }
}
