import {combineReducers} from 'redux'
import {dates} from '../../../../commons/reducers/dates'
import {demoEvents} from '../../../../commons/reducers/demo-events'
import {environment} from '../../../../commons/reducers/environment'
import {installedApps} from '../../../../commons/reducers/installed-apps'
import {instance} from '../../../../commons/reducers/instance'
import {multilingual} from '../../../../commons/reducers/multilingual'
import {siteSettings} from '../../../../commons/reducers/site-settings'
import {State} from '../types/state'
import {calendarLayout} from './calendar-layout'
import {component} from './component'
import {events} from './events'
import {layout} from './layout'
import {memberLoggedIn} from './member-logged-in'
import {owner} from './owner'
import {dynamicVeloOverrides} from './velo-overrides'

export default combineReducers<State>({
  events,
  component,
  siteSettings,
  environment,
  layout,
  calendarLayout,
  demoEvents,
  multilingual,
  instance,
  owner,
  memberLoggedIn,
  dates,
  dynamicVeloOverrides,
  installedApps,
})
