import {createAction} from '@reduxjs/toolkit'
import {createAsyncAction} from '../services/redux-toolkit'
import {State} from '../types/state'
import {reloadEvents} from './events'

interface StyleParams {
  numbers: Record<string, number>
  booleans: Record<string, boolean>
}

export const updateStyleParams = createAction<StyleParams>('UPDATE_STYLE_PARAMS')

export const updateStyles = createAsyncAction<void, StyleParams>(
  'UPDATE_STYLES',
  async (params, {dispatch, getState}) => {
    const layoutChanging = isLayoutChanging(getState(), params)
    await dispatch(updateStyleParams(params))
    if (layoutChanging) {
      dispatch(reloadEvents())
    }
  },
)

const isLayoutChanging = (state: State, params: any) => {
  const {listLayout, widgetLayout, recurringFilter} = state.component.settings
  const newParamsWithDefaults = {...state.component.settings, ...params.numbers}
  const {
    listLayout: listLayoutNew,
    widgetLayout: widgetLayoutNew,
    recurringFilter: recurringFilterNew,
  } = newParamsWithDefaults

  const isParamChanging = (newValue, oldValue) => newValue !== undefined && oldValue !== newValue

  return (
    isParamChanging(listLayoutNew, listLayout) ||
    isParamChanging(widgetLayoutNew, widgetLayout) ||
    isParamChanging(recurringFilterNew, recurringFilter)
  )
}
